:root{
  --footer_background_color: rgb(0, 0, 0);
  --header_background_color: #fff;
  --footer_bottom_background_color: #2c2c2c;
  --banner_image: url(./assets/user/images/inner-banner-shape.png);
  --font-family:'Space Grotesk', sans-serif;
  --primary_active_color: #6c3;
  --light_active_color: rgba(102,204,51 / 14%);
  --bright_active_color: rgb(120 222 69);
  --fontsize_h2:42px;
  --fontsize_h1:2.5rem;
  --fontsize_h3: 30px;
  --fontsize_h4: 16px;
  --fontsize_h5: 1.25rem;
  --fontsize_h6: 1rem;
  --paragraph : 18px;
  --primary_text_color_white: #fff;
  --paragraph_text_color: #6c6f81;
  --primary_text_color_black: #000;
  --active_text_colour:#6c3;
  --secondary_active_color: #2e2e2e;
  --color-danger: #df0a0a;
}

body{
  font-family: var(--font-family);
}
/* Dynamic Font Sizes*/
h1 {
  font-size: var( --fontsize_h1)!important; line-height: var( --fontsize_h1)!important;
}
h2 {
  font-size: var( --fontsize_h2)!important; line-height: var( --fontsize_h2)!important;
}
h3 {
  font-size: var( --fontsize_h3)!important; line-height: var( --fontsize_h3)!important;
}
h4 {
  font-size: var( --fontsize_h4)!important; line-height: var( --fontsize_h4)!important;
}
h5 {
  font-size: var( --fontsize_h5)!important; line-height: var( --fontsize_h5)!important;
}
h6 {
  font-size: var( --fontsize_h6)!important; line-height: var( --fontsize_h6)!important;
}
p {
  font-size: var( --paragraph)!important;
}

/* Footer Background Color*/
.bg-black{
  background-color:var(--footer_background_color) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cke_toolbar_break {
    display: none !important;
}
.op_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed;
  background-color: #fff;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  box-sizing: border-box;
  transition: 0.2s;
}
.op_button.danger{
  border-color: #f1388b;
  color: #f1388b;
}
.op_button.danger:hover{
  background-color: #f1388b;
  color: #fff;
  border-style: solid;
}

.op_button.success{
  border-color: #3db8ff;
  color: #3db8ff;
}
.op_button.success:hover{
  background-color: #3db8ff;
  color: #fff;
  border-style: solid;
}
.custom-select{
  height: 38px !important;
  border-radius: 5px !important;
}
.cp{
  cursor: pointer !important;
}
.cursor-no-drop{
  cursor: no-drop !important;
}
.submenu *{
  pointer-events: none !important;
}
.e_none * {
  pointer-events: none !important;
}
.row_image {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.row_image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.row_video {
  height: 50px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row_video.edit {
  height: 100px;
  width: 100px;
}
.row_video img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.row_video .vid_button{
  position: absolute;
}


.video_player {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  background-color: rgb(0 0 0 / 55%);
  display: none;
  transition: transform 0.5s;
}
.video_player.active{
  display: block;
  animation-name: videoPlayer;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  /* animation-fill-mode: forwards; */
}
@keyframes videoPlayer {
  from{
    transform: scale(0);
    opacity: 0;
  }
  to{
    transform: scale(1);
    opacity: 1;
  }
}
.video_player .content{
  padding: 25px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}
.video_player .video_area {
  display: block;
  max-width: 70vw;
  max-height: 80vh;
}
.video_player .close_player_btn {
  position: absolute;
  right: -12px;
  top: -12px;
  height: 25px;
  width: 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background: #f1388b;
  color: #fff;
  font-size: 13px;
}
.video_player .close_player_btn:hover{
  background: #fe147d;
}
.upload_progress .progress-bar {
  transition: all 0s !important;
}


/* table design >>> */
.select_head{
  width: 50px;
}
.sr_head{
  width: 50px;
}
.created_head{
  width: 150px;
}
.status_head{
  width: 100px;
}
.action_head{
  width: 200px;
}
/* table design <<< */

.main-sidebar-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.fixed_bg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  right: 0;
  top: 0;
  background: #6a252500;
  z-index: 99;
}

/** Date picker design >>>>>*/
.ui-state-default {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  user-select: none !important;
}
.ui-datepicker .ui-datepicker-calendar td a:hover {
  background-color: #9d9e9f !important;
  color: #f8f8f8 !important;
}
.ui-datepicker .ui-datepicker-calendar td a.ui-state-active {
  background-color: #3db8ff !important;
  color: #f8f8f8 !important;
}
/** Date picker design <<<<<*/

/** Multi select design >>>>>*/
.multi-select .css-1s2u09g-control {
  border-color: #e8e8f7;
}
.multi-select .css-1s2u09g-control:hover {
  border-color: #e8e8f7;
}
.multi-select .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.multi-select .css-1pahdxg-control{
  border-color: #a8afc7 !important;
  box-shadow: none;
}
/** Multi select design <<<<<*/

/**sorting design>>>>>*/
.sorting_column {
  display: flex;
}
.sorting_column span {
  margin-right: 5px;
}
/**sorting design<<<<*/

.exit_dev_mode {
  position: absolute;
  right: 0;
  top: -60px;
}
.inner-body{
  padding-top: 25px;
}
.main-loader {
  position: fixed;
  z-index: 50000;
  background: #0000005c;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
#global-loader {
  position: fixed !important;
  z-index: 50000 !important;
  background: #ffffff !important;
  left: 0 !important;
  top: 0 !important;
  height: 100vh !important;
  width: 100% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.main-sidebar-body .nav-link,.main-sidebar-body .nav-link{
  transition: all 0s !important;
}
.admin-logo {
  height: 55px;
  filter: drop-shadow(0px 1px 4px #fff);
}
.main-sidebar-hide .sidemenu-logo{
  height: auto !important;
} 
.sidemenu-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.select-down-arrow::after {
  content: "\f0d7";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 16px;
  color: #969696;
}
.select-down-arrow {
  position: relative;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: #6259ca !important;
}
.text-link {
  color: var(--background-color);
}
.otp_input {
  height:44px !important;
  width:98px !important;
  margin-right: 21px !important;
}
.custom.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.table tr.selected td{
  background-color: rgb(156 190 255 / 50%);
}
.rs-tag-closable{
  background-color: rgb(156 190 255 / 50%) !important;
}
.checkbox-wrapper{
  position: relative;
}
.checkbox-wrapper::before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 1;

}

.faq-icon {
  position: absolute;
  right: 15px;
}

.accordion-button.collapsed .faq-icon .ri-close-fill{
  display: none;
}
.accordion-button:not(.collapsed) .faq-icon .ri-add-fill{
  display: none;
}

.web-user-profile {
  height: 36px;
  width: 36px;
  object-fit: cover;
}
.social-media-action {
  display: flex;
}
.social-media-action button{
  flex: 1;
}
.no-before::before{
  display: none !important;
} 
.no-after::after{
  display: none !important;
}

/* input[type="file"]::-webkit-file-upload-button{ */
  /* height:50px; */
/* } */

.increaseBtnFile::-webkit-file-upload-button {
  height: 50px;
}

.input-phone-number{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}

.phoneInput:focus {
  outline-width: 0;
}

.selectInput:focus{
  outline-width: 0;
}
.select-check-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-check{
  display: 'unset';
  position: 'unset';
  margin: 0;
}
.select-check-label {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  left: 0;
  top: 0;
  cursor: pointer;
}
.bulk_actions {
  position: fixed;
  width: 100%;
  background: white;
  left: 0;
  bottom: 0;
  z-index: 999;
  padding: 30px 100px;
  box-shadow: 0 0 10px #ccc;
}
.bulk_actions ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}
.bulk_actions ul li{
  margin-left: 10px;
}

/* For select media work*/

.check_container {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin: 5px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check_container label {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 2px solid rgb(16, 16, 199);
  background: rgba(49, 150, 229, 0.651);
  opacity: 0;
}
.check_container img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.check_container input{
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
}
.check_container input:checked + label {
  opacity: 1;
}

.check_container i {
  font-size: 4em;
  color: #6259ca;
}
/* For select media work*/
.rs-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-modal-wrapper .invitation-modal.rs-modal{
  margin-top: 0;
}
.invitation-modal .rs-modal-content{
  height: 90vh;
  overflow: auto;
}

.rs-steps-horizontal {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.upload-button {
    height: 67vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-group {
  background: #6259ca14;
}
.filter_design .rs-picker-toggle-textbox{
  opacity: 1;
}
.filter_design .rs-picker-toggle-textbox::placeholder{
  opacity: 1;
  color: #a8afc7;
}
.filter_design i{
  opacity: 1;
  color: #a8afc7;
}
.filter_design select{
  color: #a8afc7;
  padding-left: 30px !important;
  padding-right: 20px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.select-check-container input:indeterminate + span::before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #6259ca;
  border: 1px solid #ccd3e4 !important;
  top: 1px;
  left: 0;
  border-radius: 3px;
}
.select-check-container input:indeterminate + span::after {
  content: "";
  width: 10px;
  height: 3px;
  background-color: #ffffff;
  border: none;
  top: 7px;
  left: 50%;
  display: block;
  transform: translateX(-50%);
}
.media-box
{
height: 197px;
}
.modal-height-auto .rs-modal-content{
height:unset;
}
.fileName-gallery {
  position: absolute;
  left: 17px;
  top: 2px;
  z-index: 1;
  background: #fff;
  padding: 0px 5px;
  border-radius: 5px;
  font-size: 11px;
  width: calc(100% - 34px);
}
.requirestar {
  position: relative;
  color: red;
}

.mlAction{
  margin-left:5px;
}
.input-group-append {
  pointer-events: none;
}
.react-datepicker__navigation--years-previous, .react-datepicker__navigation--years-upcoming{
  position: relative;
}
.react-datepicker__navigation--years-previous::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: red;
  left: 0;
  top: 0;
  background: url('data:image/svg+xml, <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-chevron-down\" viewBox=\"0 0 16 16\"><path fill-rule=\"evenodd\" d=\"M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}
.react-datepicker__navigation--years-upcoming::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: red;
  left: 0;
  top: 0;
  background: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}
.disabled-link:not(.active){
  color: #9f9f9f !important;
  user-select: none;
  cursor: no-drop;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 1px;
  left: -14px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: 0px;
  border-bottom-color: #aeaeae;
  left: -14px;
}
.linkactive.active {
  background-color: transparent !important;
  color: #6259ca !important;
  border-bottom: 2px solid #6259ca;
}
.rsuite-input {

}

.rsuite-input:hover {
  border-color: #a8afc7 !important;
}
.rsuite-input, .rsuite-input.rs-picker-focused {
  border-color: #a8afc7 !important;
  box-shadow: none !important;
}
label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: #6259ca !important;
}
.rs-checkbox-checked .rs-checkbox-inner:before {
  background-color: #6259ca!important;
  border-color: #6259ca !important;
}
.rs-check-item.rs-check-item-focus, .rs-check-item:focus, .rs-check-item:not(.rs-checkbox-disabled):hover {
  background-color: rgb(98 89 202 / 17%) !important;
}
.rs-tag-closable {
  background-color: rgb(98 89 202 / 23%) !important;
}
.price-text {
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 700;
}

.field-more-info {
  height: 15px;
  display: flex;
  width: 15px;
  background: #000;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}
.swal2-timer-progress-bar {
  background: #a5dc86;
}

.qr-code{
  height:50px;
  border: #000;
  display: flex;
}
.qr-code-admin{
  height:150px;
  border: #000;
  display: flex;
}
.qr-code-user{
  height:100px;
  position: absolute;
  width: 60px;
  right:20px;
  border: #000;
}
.agenda_ticket{
  background-color: #e6e7f6 !important;
}
.hotel_ticket{
  background-color: hsl(127, 38%, 43%) !important;
}
.event_ticket{
  background-color: cornflowerblue !important;
}
.permission-container {
  height: 82vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 10px;
}
.permission-container .permission-alert{
  color: red;
}

.join{
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/**rsuite tooltip >>>>>*/
.rs-tooltip {
	background-color: #272c36;
	border-radius: 4px;
	color: #fff;
	display: block;
	font-size: 12px;
	line-height: 1.66666667;
	max-width: 250px;
	opacity: 0;
	overflow-wrap: break-word;
	padding: 2px 10px;
	position: absolute;
	z-index: 1070
}

.rs-tooltip.rs-anim-fade {
	-webkit-transition: opacity .1s linear, -webkit-transform .1s ease-out;
	transition: opacity .1s linear, -webkit-transform .1s ease-out;
	transition: opacity .1s linear, transform .1s ease-out;
	transition: opacity .1s linear, transform .1s ease-out, -webkit-transform .1s ease-out
}

.rs-tooltip.rs-anim-in {
	opacity: 1;
	-webkit-transition: opacity .15s linear, -webkit-transform .15s ease-in;
	transition: opacity .15s linear, -webkit-transform .15s ease-in;
	transition: opacity .15s linear, transform .15s ease-in;
	transition: opacity .15s linear, transform .15s ease-in, -webkit-transform .15s ease-in
}

.rs-tooltip-arrow:after,
.rs-tooltip-arrow:before {
	border-color: transparent;
	border-style: solid;
	content: " ";
	display: block;
	height: 0;
	position: absolute;
	width: 0
}

.rs-tooltip-arrow:before {
	display: none
}

.rs-anim-fade {
	opacity: 0;
	pointer-events: none;
	-webkit-transition: opacity .15s linear;
	transition: opacity .15s linear
}

.rs-anim-fade.rs-anim-in {
	opacity: 1;
	pointer-events: unset
}
.rs-tooltip.rs-anim-fade {
	-webkit-transition: opacity .1s linear, -webkit-transform .1s ease-out;
	transition: opacity .1s linear, -webkit-transform .1s ease-out;
	transition: opacity .1s linear, transform .1s ease-out;
	transition: opacity .1s linear, transform .1s ease-out, -webkit-transform .1s ease-out
}
.rs-tooltip.rs-anim-in {
	opacity: 1;
	-webkit-transition: opacity .15s linear, -webkit-transform .15s ease-in;
	transition: opacity .15s linear, -webkit-transform .15s ease-in;
	transition: opacity .15s linear, transform .15s ease-in;
	transition: opacity .15s linear, transform .15s ease-in, -webkit-transform .15s ease-in
}

.rs-tooltip[class*=placement-top] {
	margin-top: -8px;
	-webkit-transform: translate(0);
	transform: translate(0)
}

.rs-tooltip[class*=placement-top].rs-anim-in {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px)
}

.rs-tooltip[class*=placement-top]:after {
	border-top-color: #272c36;
	border-width: 6px 6px 0;
	bottom: -6px;
	margin-left: -6px
}

.rs-anim-fade.rs-anim-in {
	opacity: 1;
	pointer-events: unset
}
.rs-tooltip[class*=placement-bottom] {
	margin-top: 8px;
	-webkit-transform: translate(0);
	transform: translate(0)
}

.rs-tooltip[class*=placement-bottom].rs-anim-in {
	-webkit-transform: translateY(2px);
	transform: translateY(2px)
}

.rs-tooltip[class*=placement-bottom]:after {
	border-bottom-color: #272c36;
	border-bottom-color: var(--rs-tooltip-bg);
	border-width: 0 6px 6px;
	margin-left: -6px;
	top: -6px
}
.rs-tooltip[class*=placement-right] {
	margin-left: 8px;
	-webkit-transform: translate(0);
	transform: translate(0)
}

.rs-tooltip[class*=placement-right].rs-anim-in {
	-webkit-transform: translate(2px);
	transform: translate(2px)
}

.rs-tooltip[class*=placement-right]:after {
	border-right-color: #272c36;
	border-right-color: var(--rs-tooltip-bg);
	border-width: 6px 6px 6px 0;
	left: -6px;
	margin-top: -6px
}
.rs-tooltip[class*=placement-left] {
	margin-left: -8px;
	-webkit-transform: translate(0);
	transform: translate(0)
}

.rs-tooltip[class*=placement-left].rs-anim-in {
	-webkit-transform: translate(-2px);
	transform: translate(-2px)
}

.rs-tooltip[class*=placement-left]:after {
	border-left-color: #272c36;
	border-left-color: var(--rs-tooltip-bg);
	border-width: 6px 0 6px 6px;
	margin-top: -6px;
	right: -6px
}

.rs-tooltip.placement-bottom:after,
.rs-tooltip.placement-bottom:before,
.rs-tooltip.placement-top:after,
.rs-tooltip.placement-top:before {
	left: 50%
}

.rs-tooltip.placement-bottom-start:after,
.rs-tooltip.placement-bottom-start:before,
.rs-tooltip.placement-top-start:after,
.rs-tooltip.placement-top-start:before {
	left: 10px
}

.rs-tooltip.placement-bottom-end:before,
.rs-tooltip.placement-top-end:before {
	right: 3px
}

.rs-tooltip.placement-bottom-end:after,
.rs-tooltip.placement-top-end:after {
	right: 4px
}

.rs-tooltip.placement-left:after,
.rs-tooltip.placement-left:before,
.rs-tooltip.placement-right:after,
.rs-tooltip.placement-right:before {
	top: 50%
}

.rs-tooltip.placement-left-start:after,
.rs-tooltip.placement-left-start:before,
.rs-tooltip.placement-right-start:after,
.rs-tooltip.placement-right-start:before {
	top: 10px
}

.rs-tooltip.placement-left-end:before,
.rs-tooltip.placement-right-end:before {
	bottom: 3px
}

.rs-tooltip.placement-left-end:after,
.rs-tooltip.placement-right-end:after {
	bottom: 4px
}
/**rsuite tooltip <<<<<*/

.dashboard-filter-select
{
  /* min-width: unset !important; */
  min-width: 150px !important;
}
.dashboard-filter-select.rs-picker-default .rs-picker-toggle.rs-btn-sm{
  padding-left: 0 !important;
}
.dashboard-filter-select.rs-picker-default .rs-stack-item svg{
  display: block !important;
}
.button-users {
  position: absolute;
  right: 0;
  bottom: 0;
}
.button-users button {
  min-width: unset;
  height: 30px !important;
}

.button-admin {
  right: 40px !important;
  bottom: 15px;
  position: absolute !important;
}

.input_contact:focus {
  outline: none;
}

.back-button{
  margin-bottom: 10px;
  margin-left: 20px;
}

.delete-btn {
  position:absolute;
  top:12px; 
  right:20px; 
  z-index:1; 
  height:100; 
  width:40; 
  margin-top:10px;
}
.emoji-btn {
  position: absolute;
  right: 17px;
  top: 38px;
}
.chat-limit {
  margin-left: 22px;
  margin-top: -22px;
  position: absolute;
  font-size: 14px;
}
.chat-menu {
  position: absolute;
  top: 4px;
  right: 0;
}
.chat-menu-left {
  position: absolute;
  top: 5px;
  left: 10px;
}
.user-ticket {
  left: 615px !important;
  top: 500px !important;
}
.disable-click{
  /* pointer-events: none !important; */
}

.disable-input{
  pointer-events: none !important;
  filter: contrast(0.5);
}

.mystyle{
  display: none;
}
.collapse-overflow{
  transition: all 0.5s;
}
.max-100{
  max-height: 93px;
  overflow: hidden;
}
.show-more {
  border: none;
  background: none;
  font-size: 12px;
  color: var(--active_text_colour);
}

.note-message{
  text-align: left;
  color: red;
  margin-top: 20px;
}
.ovr-scroll {
  max-height: 80vh;
  overflow: auto;
}
.download-file{
  color: blue !important;
  text-decoration: underline !important;
}
.modal-newsletters{
max-height: 80vh !important;
overflow: auto !important;
}


/***/


textarea.form-control.signature-sec {
  height: 120px;
  resize: none;
}

.btn{    border-radius: 6px !important;}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  opacity: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.upload-btn-wrapper{position:relative;display:inline-block}
.upload-btn{background:#fff;color:#01147b;border:1px solid #01147b;padding:10px 12px;border-radius:4px;height:44px;font-size:15px;font-weight:600}
.upload-profile figure{width:154px;height:154px;margin:auto;border-radius:50%}
.upload-profile figure img{width:100%;height:100%;object-fit:cover}


.upload-btn {
  background: #fff;
  color: #6c6f81;
  border: 1px solid #dce3eb;
  padding: 10px 12px;
  border-radius: 4px;
  min-height: 350px;
  font-size: 15px;
  font-weight: 400;
  min-width: 300px;
}

.btn-group {
  display: inline-block;}


  .form-check-input{    margin-top: 4px;}

  .adv-filters-sec select {
    width: auto;
    opacity: 0.9;
}

    .adv-filters-sec {
      flex-wrap: wrap;
      justify-content: flex-end;
  }


.table-bordered>:not(caption)>*>* {
  border-width: thin;
} 


.adv-table tr th {
  background: rgb(221 221 221 / 30%);
  font-size: 15px;
  font-weight: 500;
}

.row.home-content h2 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.row.home-content p {
  margin-bottom: 20px;
}
.like-question-container input:checked + label i{
  color: var(--active_text_colour) !important;
}
.draw-actions-list{
  list-style: none;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
}
.draw-actions-list li a{
  text-decoration: none;
  display: block;
  padding: 4px 8px;
}
.line-height-normal{
  line-height: normal !important;
}
.cropper-container{
  position: relative;
  display: inline-block;
}
.cropper-container .button-list {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  padding: 0;
  display: flex;
}
.cropper-container .button-list .m_btn {
  background: none;
  border: none;
  outline: none;
  color: #fff;
  height: 30px;
  width: 30px;
}
.cropper-container .button-list .m_btn.success {
  background: var(--primary_active_color);
}
.cropper-container .button-list .m_btn.danger {
  background: var(--color-danger);
}
.cropper-container .button-list .m_btn:hover {
  opacity: 0.7;
}
.table-btn-icon { width: 38px;min-width: 38px;height: 38px;}
.table tr th { font-weight: 700;}
.table tr th,.table tr td { font-size: 14px; vertical-align: middle;}
.footer-content h3 { font-size: 20px;}
.recording-box { display: flex;align-items: center;border-radius: 15px;border: 1px solid #cccc;background: #f3f3f3;padding: 15px;}
.recording-box .buttons { margin-left: auto;}
.recording-box h4 { font-size: 16px !important;}
.recording-box .mic { min-width: 45px; width: 45px;height: 45px;background: #fff;border-radius: 45px;display: flex;align-items: center;justify-content: center;margin-right: 10px;}
.recording-box .mic i { font-size: 28px !important;}
.record-text { display: flex;align-items: start;flex-direction: column;justify-content: center; }
.form-radio-label{
  font-size: 14px;
  color: #77718f;
  font-weight: 500;
  padding-left: 5px;
}
.rangeslider-horizontal {
  height: 3px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: var(--active_text_colour);
  box-shadow: none;
}
.rangeslider .rangeslider__handle {
  background: var(--active_text_colour);
  border: 1px solid var(--active_text_colour);
  box-shadow: none;
  width: 24px;
  height: 24px;
  cursor: grab;
}
.rangeslider-horizontal .rangeslider__handle:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.rangeslider__handle-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 21px;
  color: #fff;
}
.option-emoji{
  filter: grayscale(1);
}
.option-emoji-input:checked + label .option-emoji{
  filter: grayscale(0);
}
.survey-questions-titles{
  font-size: 22px;
  color: #1c1818;
  font-weight: 700;
  padding-bottom: 18px;
}
.form-like-input{
  appearance: none;
  -webkit-appearance: none;
}
.form-like-input:checked + .form-like-label{
  color: var(--active_text_colour);
}
.form-like-label{
  font-size: 28px;
}
.draw-actions-list{
  list-style: none;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
}
.draw-actions-list li a{
  text-decoration: none;
  display: block;
  padding: 4px 8px;
}
.drawing-lock {
  position: absolute;
  background: transparent;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: not-allowed;
}
.drawn-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.cropper-container .center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.scan-animation {
  width: 100%;
  height: 30px;
  background: linear-gradient(cyan, transparent);
  animation: scanning 1.5s linear alternate infinite;
  position: absolute;
  left: 0;
  top: 0;
}


@keyframes scanning {
  0% { top: 0px; }
  100% { top: calc(100% - 30px); }
}
.custom-checks{
  position: relative;
  display: inline-block;
}
.custom-checks label{
  border: 1px solid #ccc;
  padding: 2px 22px;
  border-radius: 20px;
  font-size: 14px;
}
.custom-checks .custom-checks-input{
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);opacity: 0;
  cursor: pointer;
}
.custom-checks .custom-checks-input:checked + label{
  background: var(--active_text_colour);
  color: #fff;
  border-color: var(--active_text_colour);
}
.outline-0{
  outline: none !important;
}
.no-wrap{
  white-space: nowrap !important;
}
.generated-link{
  text-align: left;
  background: #6259ca1c;
  padding: 10px 15px;
  border-radius: 5px;
  word-wrap: break-word;
}
.question-image-preview{
  display: flex;list-style: none;
}
.question-image-preview .pr_img_box {
  height: 80px;
  width: 110px;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.question-image-preview .pr_img_box img{
  height: 100%;
  width:  100%;
  object-fit: cover;
}
.question-image-preview .pr_img_box .img_options{
  height: 100%;
  width:  100%;
  position: absolute;
  left: 0;
  bottom: -110%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.265);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.question-image-preview .pr_img_box:hover .img_options{
  bottom: 0;
}
.question-image-preview .pr_img_box .img_options button {
  background: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 0 5px;
  outline: none;
}
.files-count-container{
  position: relative;
}
.files-count-container .files-count {
  position: absolute;
  left: 109px;
  height: calc(100% - 2px);
  top: 1px;
  width: calc(100% - 110px);
  display: flex;
  align-items: center;
  background: #fff;
  padding-left: 8px;
  border-radius: 8px;
  pointer-events: none;
}
.question-video-preview{
  display: flex; list-style: none;
}
.question-video-preview .pr_video_box {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 5px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}
.question-video-preview .pr_video_box .play_btn{
  font-size: 14px;
}
.vr_divider {
  width: 1px;
  background: #ccc;
  height: 100%;
  margin: 4px;
}
.btn-hover:hover{
  opacity: 0.7;
}
.no-select{
  user-select: none;
}
.question-video-upload-progress{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
}
.question-video-upload-progress .progress {
  height: 100%;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.588);
}
.question-video-upload-progress .progress .progress-bar{
  background-color: var(--active_text_colour);
}
.question-video-upload-progress .progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f4f4f4;
  font-size: 12px;
  text-shadow: 0 0 #070707;
}
.lg-backdrop {
  z-index: 9999 !important;
}
.lg-outer {
  z-index: 99999 !important;
}
.demo-avatar-group .lg-react-element {
  display: flex;
}
.show-drawing .lg-object{
  background: #fff;
}
.audio-preview{
  height: 30px;
  width: 120px;
}
.user-view-registration.question_type-photo_upload .demo-avatar-group, .user-view-registration.question_type-signature .demo-avatar-group , .user-view-registration.question_type-free_hand_drawing .demo-avatar-group {
  display: inline-flex;
  align-items: center;
  padding-left: 9px;
}
.user-view-registration.question_type-photo_upload .demo-avatar-group .main-img-user, .user-view-registration.question_type-signature .demo-avatar-group .main-img-user,.user-view-registration.question_type-free_hand_drawing .demo-avatar-group .main-img-user {
  width: 32px;
  height: 32px;
  font-size: 14px;
  display: block;
  position: relative;
  border-radius: 100%;
  margin-left: -9px;
}
.user-view-registration.question_type-photo_upload .demo-avatar-group .main-img-user img, .user-view-registration.question_type-signature .demo-avatar-group .main-img-user img, .user-view-registration.question_type-free_hand_drawing .demo-avatar-group .main-img-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid var(--active_text_colour);
}
.event-img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.admin-view-registration.question_type-photo_upload .demo-avatar-group .main-img-user, .admin-view-registration.question_type-signature .demo-avatar-group .main-img-user,.admin-view-registration.question_type-free_hand_drawing .demo-avatar-group .main-img-user {
  width: 40px;
  height: 29px;
  font-size: 11px;
  display: block;
  position: relative;
  border-radius: 100%;
  margin-left: -9px;
  margin-top:2px;
}
.admin-view-registration.question_type-photo_upload .demo-avatar-group .main-img-user img, .admin-view-registration.question_type-signature .demo-avatar-group .main-img-user img, .admin-view-registration.question_type-free_hand_drawing .demo-avatar-group .main-img-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid var(--active_text_colour);
}
.admin-view-registration.question_type-photo_upload .demo-avatar-group, .admin-view-registration.question_type-signature .demo-avatar-group , .admin-view-registration.question_type-free_hand_drawing .demo-avatar-group {
  display: inline-flex;
  align-items: center;
  padding-left: 9px;
}
.questions-ul{
  padding: 0;
  list-style: none;
}
.admin-edit-reg-questions.single_choice .form-radio-label::after{
 display: none;
}
.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn.dropdown-toggle-split:first-child, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.name-badge-preview{
  position: relative;
  height: 100%;
  width: 100%;
}
.name-badge-preview .pre-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name-badge-content{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 15px;
}
.table-top-button-bar{
  position: absolute;
  right: 0;
  top: -73px;
}
/* .fc-scrollgrid-sync-table {
  width: 100px;
} */


.name-badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.name-badge-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Adjust as needed */
}

.name-badge-below-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h-45px{
  height: 45px !important;
}
/* For cookies modal */
.wrapper-cookies {
  position: fixed;
  right: 20px;
  bottom: 50px;
  max-width: 345px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 15px 25px 22px;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px 9px rgba(0, 0, 0, 0.1);
  z-index: 99999;
}
.wrapper-cookies.show {
  right: 20px;
}
.title-box-cookies {
  display: flex;
  align-items: center;
  column-gap: 15px;
  color: #2e2e2e;
  margin-bottom: 15px;
}
.title-box-cookies i {
  font-size: 32px;
}
.title-box-cookies h3 {
  font-size: 24px;
  font-weight: 500;
}
.info-cookies {
  margin-bottom: 15px;
}
.info-cookies p {
  font-size: 16px;
  font-weight: 400;
  color: #333;
}
.info-cookies p a:hover {
  text-decoration: underline;
}
.cookies-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cookies-button {
  width: calc(100% / 2 - 10px);
  padding: 8px 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  outline: none;
  box-shadow: none;
  border: none;
}
.cookies-button.accept{
  background-color: var(--active_text_colour);
}
/* For cookies modal */

.right{
  right:0 !important;
  left: auto !important;
}
.invite-member-container {
  display: flex;
  align-items: center;
}

.selected-members {
  margin-left: 10px; 
}


.manual-invitees-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.chip {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border-radius: 16px;
  font-size: 14px;
  margin: 5px;
}

.chip .close-btn {
  background: none;
  border: none;
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.chip .close-btn:hover {
  color: #ff0000;
}

.selected-members {
  margin-top: 10px;
}

.invite-member-container {
  margin-top: 20px;
}

.btn-main-primary {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-main-primary:hover {
  background-color: #0056b3;
}

.cp {
  cursor: pointer;
}
.qr-code-booth-attendants{
  height: 350px;
  width: 350px;
  margin: 20px auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
/** Blocking header navbar animation >>>> */
.topHeader .navbar-collapse, .topHeader .navbar-collapse .navbar-nav {
  transition: all 0s !important;
}
/** Blocking header navbar animation <<<< */

.print-badge{
  /* background: url('https://images.unsplash.com/photo-1718202248135-c18af6f6299a?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');background-position: center;background-size: cover; */
  padding: 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}
.print-badge-1, .print-badge-2 {
  background-color:rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  overflow: hidden;

}
.print-badge-1 span, .print-badge-2 span { display: block; }
.checkin-checkout-ticket-checkbox {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
}


.export-table-columns{
  position: absolute;
  right: 0;
  top: 100%;
  padding: 15px;
  min-width: 220px;
  background-color: #fff;
  box-shadow: 0 0 15px #00000027;
  z-index: 9999;
  max-height: 300px;
  overflow: auto;
}
.export-table-columns ul{
 list-style: none;
 padding: 0;
 margin: 0;
}
.fixed-overlay-export-columns{
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  z-index: 999;
}

.export-table-columns ul li *{
  white-space: nowrap !important;
}
