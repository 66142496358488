.outerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #1A1A1D;
}

/* .container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #FFFFFF;
	border-radius: 8px;
	height: 60%;
	width: 35%;
} */
.chat-buttons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    height: 100%;
}
.chat-input-button {
    height: 100%;
    width: 40px;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 20px;
}
.chat-input-button:hover {
	background: var(--light_active_color);
}
.write_msg{
	padding-right: 84px;
}
.emoji-picker-container {
	position: absolute;
    bottom: 0;
    right: 0;
}
.chat-buttons li:nth-last-child() .chat-input-button {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

@media (min-width: 320px) and (max-width: 480px) {
	.outerContainer {
		height: 100%;
	}

	/* .container {
		width: 100%;
		height: 100%;
	} */
}

@media (min-width: 480px) and (max-width: 1200px) {
	/* .container {
		width: 60%;
	} */
}
