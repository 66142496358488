.infoBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #2979FF;
	border-radius: 4px 4px 0 0;
	height: 60px;
	width: 100%;
}

.leftInnerContainer {
	flex: 0.5;
	display: flex;
	align-items: center;
	margin-left: 5%;
	color: white;
}

.rightInnerContainer {
	display: flex;
	flex: 0.5;
	justify-content: flex-end;
	margin-right: 5%;
}

.onlineIcon {
	margin-right: 5%;
}
.chat_leave_btn {
    position: absolute;
    right: 15px;
    -webkit-user-select: none;
    user-select: none;
    color: var(--active_text_colour);
    text-decoration: none;
    font-size: 25px;
}
